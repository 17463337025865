const colors = {
  grey1: '#e5e6e6',
  grey2: '#e6e6e6',
  grey3: '#41464e',
  grey4: '#686868',
  grey5: '#2e3238',
  white: '#fff',
  orange: '#EE9648',
}
export default colors
